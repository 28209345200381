<!--
 * @Author: your name
 * @Date: 2021-01-28 19:42:51
 * @LastEditTime: 2024-09-26 14:14:40
 * @LastEditors: hl
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/newIndex/components/FromWorld.vue
-->
<template>
  <div
    class="from_con"
    :class="$t('languages') == 'EN' ? '' : 'zh_from_con_font'"
  >
    <div
      v-if="title"
      class="font24 font500w title_1"
      :class="$t('languages') == 'EN' ? '' : 'zh_title_1_font'"
    >
      {{ title }}
    </div>
    <div v-if="details" class="font18 details">{{ details }}</div>
    <div v-if="details1" class="font18">{{ details1 }}</div>
    <div v-if="more" class="font18 more_know" @click="goPath(path)">
      <span>{{ more }}</span>
      <img src="@/assets/img/index/go_more1@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    details: {
      type: String,
      default: "",
    },
    details1: {
      type: String,
      default: "",
    },
    more: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
  methods: {
    goPath(path) {
      if (
        (!localStorage.getItem("AZG_TOKEN") ||
          localStorage.getItem("AZG_TOKEN") === null) &&
        path !== "/companyintr"
      ) {
        this.$router.push("/login");
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>
<style scoped lang="less">
.from_con {
  // background: #c4c4c4;
  margin: calc(100vw * 5 / 192) 0;
  .title_1 {
    color: #bf9264;
    margin-bottom: calc(100vw * 2.4 / 192);
  }
  .more_know {
    margin-top: calc(100vw * 2.6 / 192);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      display: block;
      width: calc(100vw * 2.4 / 192);
      margin-left: calc(100vw * 0.8 / 192);
    }
  }
  .details {
    padding: 0 30%;
  }
}
.zh_from_con_font {
  font-family: FZLanTingYuanS-EL-GB;
}
.zh_title_1_font {
  font-family: FZLanTingYuanS-DB-GB;
}
</style>
